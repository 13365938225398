import React from 'react';
import './column.css';
import Task from './../task/index'
import { ReactComponent as AddTaskIcon } from './../../../../assets/icons/add.svg'


class AddTaskButton extends React.PureComponent {
    render() {
        return (
            <div className={this.props.isEmptyColumn ? 'add-task-container-empty-column' : 'add-task-container'}>
                <AddTaskIcon className='add-task-icon'/>
                <p className='add-task-text'>Add Task</p>
            </div>
        )
    }
}

class Column extends React.PureComponent {
    render() {
        const tasks = this.props.tasks.map(task => <Task {...task}/>)
        return (
            <div className='column-container'>
                <div className='column-header'>
                    <h3>{this.props.title}</h3>
                </div>
                <div className={this.props.tasks.length == 0 ? 'empty-tasks-container' : ''}>
                    {tasks}
                    <AddTaskButton isEmptyColumn={this.props.tasks.length == 0}/>
                </div>
            </div>
        )
    }
}

export default Column;