import React from 'react';
import './task.css';
import { ReactComponent as MyTasksIcon } from './../../../../assets/icons/tasks.svg'

class Task extends React.PureComponent {
    render() {
        return (
            <div className='task-container'>
                <div className='task-project-pill'/>
                <div className='task-title-container'>
                    <MyTasksIcon className='task-icon'/>
                    <p className='task-text'>{this.props.title}</p>
                </div>
                <div className='task-date-container'>
                    <p className='task-date'>{this.props.date}</p>
                </div>
            </div>
        )
    }
}

export default Task;