import React from 'react';
import './homepage.css';
import Header from './header/index.js';
import SecondaryHeader from './secondaryHeader/index.js';
import Board from './board/index.js';

class HomePage extends React.PureComponent {
    render() {
        return (
            <div className='container'>
                <Header/>
                <SecondaryHeader/>
                <Board/>
            </div>
        )
    }
}

export default HomePage;