import React from 'react';
import './header.css';
import { ReactComponent as AddIcon } from './../../../assets/icons/add.svg'
import { ReactComponent as SearchIcon } from './../../../assets/icons/search.svg'


const tabs = ['List', 'Board', 'Calendar', 'Files']

class TabItem extends React.PureComponent {
    render() {
        return (
            <div className={this.props.isActive ? 'tab-item-active' : 'tab-item'}>
                {this.props.text}
            </div>
        )
    }
}

class SearchBar extends React.PureComponent {
    render() {
        return (
            <div className='search-bar-container'>
                <SearchIcon className='search-icon'/>
                <p className='search-bar-text'>Search</p>
            </div>
        )
    }
}

class Header extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            currentTab: 'Board'
        }
    }
    render() {  
        const tabItems = tabs.map(tab => <TabItem text={tab} isActive={tab == this.state.currentTab}/>);
        return (
            <div className='header-container'>
                <div className='main-header'>
                    <div className='profile-icon'>
                        <p>Al</p>
                    </div>
                    <div className='sub-header'>
                        <div className='title-container'>
                            <h1>My Tasks</h1>
                        </div>
                        <div className='tab-container'>
                            {tabItems}
                        </div>
                    </div>
                </div>
                <div className='side-header'>
                    <div className='profile-icon-small'>
                        <p className='small-font'>Al</p>
                    </div>
                    <div className='plan-container'>
                        <div className='plan-text-container'>
                            <p className='plan-text-1'>Trial: 30 days left</p>
                            <p className='plan-text-2'>Select plan</p>
                        </div>
                    </div>
                    <div className='add-icon-container'>
                        <AddIcon className='add-icon'/>
                    </div>
                    <SearchBar/>
                </div>
            </div>
        )
    }
}

export default Header;