import React from 'react';
import './secondaryHeader.css';

class SecondaryHeader extends React.PureComponent {
    render() {
        return (
            <div className='secondary-header-container'>
                <p className='secondary-header-text'>Created Today</p>
            </div>
        )
    }
}

export default SecondaryHeader;