import React from 'react';
import {FaHome} from 'react-icons/fa'
import './sidebar.css';
import { ReactComponent as AsanaIcon } from './../../assets/icons/asana.svg'
import { ReactComponent as SideBarToggleIcon } from './../../assets/icons/sidebar-toggle.svg'
import { ReactComponent as HomeIcon } from './../../assets/icons/home.svg'
import { ReactComponent as MyTasksIcon } from './../../assets/icons/tasks.svg'
import { ReactComponent as InboxIcon } from './../../assets/icons/inbox.svg'
import { ReactComponent as ReportingIcon } from './../../assets/icons/reporting.svg'
import { ReactComponent as PortfoliosIcon } from './../../assets/icons/portfolios.svg'
import { ReactComponent as GoalsIcon } from './../../assets/icons/goals.svg'
import { ReactComponent as InviteTeammatesIcon } from './../../assets/icons/invite.svg'
import { ReactComponent as HelpIcon } from './../../assets/icons/help.svg'

const navigationButtons = [
    {
        text: 'Home',
        icon: HomeIcon
    },
    {
        text: 'My Tasks',
        icon: MyTasksIcon
    },
    {
        text: 'Inbox',
        icon: InboxIcon
    },
    {
        text: 'Reporting',
        icon: ReportingIcon
    },
    {
        text: 'Portfolios',
        icon: PortfoliosIcon
    },
    {
        text: 'Goals',
        icon: GoalsIcon
    },
]

const secondaryNavigationButtons = ['Favorites', 'Saved Searches', 'Teams', 'Browse Other Teams']

const secondaryNavigationButtonsFooter = [
    {
        text: 'Invite Teammates',
        icon: InviteTeammatesIcon
    },
    {
        text: 'Help & getting started',
        icon: null
    },
]

class NavigationButton extends React.PureComponent {
    render() {
        const Icon = this.props.icon;
        return (
            <div className='navigation-button-container'>
                <Icon className='navigation-button-icon'/>
                <p className='navigation-button-text'>{this.props.text}</p>
            </div>
        )
    }
}
class SecondaryNavigationButton extends React.PureComponent {
    render() {
        const Icon = this.props.icon;
        const containerClass = 'secondary-navigation-button-container' + (this.props.isInFooter ? '-footer' : '');
        const textClass = 'secondary-navigation-button-text' + (this.props.isInFooter ? '-footer' : '');
        return (
            <div className={containerClass}>
                {Icon && <Icon className='secondary-navigation-button-icon'/>}
                <p className={textClass}>{this.props.text}</p>
            </div>
        )
    }
}

class SideBar extends React.PureComponent {
    render() {
        const buttons = navigationButtons.map(entry => <NavigationButton {...entry}/>);
        const secondaryButtons = secondaryNavigationButtons.map(text => <SecondaryNavigationButton isInFooter={false} text={text}/>)
        const secondaryButtonsFooter = secondaryNavigationButtonsFooter.map(data => <SecondaryNavigationButton isInFooter={true} {...data}/>)
        return (
            <div className='sidebar-container'>
                <div>
                    <div className='header'>
                        <AsanaIcon className='asana-icon'/>
                        <SideBarToggleIcon className='sidebar-toggle-icon'/>
                    </div>
                    <div className='navigation-container'>
                        {buttons}
                    </div>
                    {secondaryButtons}
                </div>
                <div>
                    {secondaryButtonsFooter}
                </div>
            </div>
        )
    }
}

export default SideBar;