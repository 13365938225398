import './App.css';
import SideBar from './components/sidebar/index'
import HomePage from './components/homepage/index'

function App() {
  return (
    <div className='app'>
      <div className='sidebar'>
        <SideBar/>
      </div>
      <div className='homepage'>
        <HomePage/>
      </div>
    </div>
  );
}

export default App;
