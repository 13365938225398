import React from 'react';
import './board.css';
import Column from './column/index'

const columns = [
    {
        title: 'Recently Assigned',
        tasks: [
            {
                title: 'Deploy Your Work',
                date: 'Sep 24'
            },
        ]
    },
    {
        title: 'Do Today',
        tasks: [
            {
                title: 'Design The Front-end',
                date: 'Sep 25'
            },
        ]
    },
    {
        title: 'Do Next Week',
        tasks: []
    },
    {
        title: 'Do Later',
        tasks: []
    },

]

class Board extends React.PureComponent {
    render() {
        const columnElements = columns.map(column => <Column {...column}/>);
        return (
            <div className='board-container'>
                {columnElements}
            </div>
        )
    }
}

export default Board;